<script>
  import {router} from "tinro";
  import api from "..//api";
  import {activeCase} from "..//store";
  import Document from "..//components/Document.svelte";
  import Upload from "..//components/Upload.svelte";

  let working = false;
  let history = null;

  activeCase.subscribe(async (c) => {
      if (c) {
          history = await api.documents(false);
      } else {
          history = null;
      }
  });

</script>


<div class="container">
    <h1>Documents</h1>
    <p>
        Below you will find any documents we have sent you related to your loan and a history of the documents you have provided to us.
    </p>
    <p>
        If you need to send us a document, that isn't on your to-do list, from your device such as a photo you've taken or a document you've
        scanned, you can do so below.
    </p>

    <Upload class="upload" on:uploaded={(e) => {
       let payload = e;
        while ('detail' in payload) {
            payload = payload.detail;
        }
        const files = payload.completed.map(c => c.doc);
        history = history === null ? files : [...history, ...files]}
        } />

    <h2>From Us</h2>
    {#await api.documents(true)}
        <p>Loading...</p>
    {:then docs}
        {#if docs.length}
            <div class="documents">
                {#each docs as doc, index (`${doc.fileName}_${index}`)}
                    <Document doc={doc}/>
                {/each}
            </div>
        {:else}
            <p>We haven't sent you any further documents yet.</p>
        {/if}
    {/await}

    <h2>From You</h2>
    {#if !history}
        <p>Loading...</p>
    {:else}
        <div class="documents">
            {#if history.length}
                {#each history as doc, index (`${doc.fileName}_${index}`)}
                    <Document doc={doc}/>
                {/each}
            {:else}
                <p>We haven't received any documents from you yet.</p>
            {/if}
        </div>
    {/if}

</div>

<style>
.documents, .packs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
}
@media screen and (max-width: 1236px) {
    .documents {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 767px) {
    .documents {
        grid-template-columns: 1fr;
    }
}</style>
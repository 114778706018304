<script>
    import {onMount} from "svelte";
    import {router} from "tinro";
    import api from "../api";
    import {user} from "../store";

    export let documentCode, signerCode;

    let working = false;
    let eSignUrl;

    // eversign events
    window.addEventListener("message", e => {
        if (e.data === "event_loaded") {
        } else if (e.data === "event_signed") {
            eSignUrl = null;
            router.goto("/e-sign/complete?event=signing_complete");
        } else if (e.data === "event_declined") {
            eSignUrl = null;
        } else if (e.data === "event_error") {
            eSignUrl = null;
        }
        working = false;
    });

    onMount(async () => {
        working = true;
        const result = await api.eSignByCode(documentCode, signerCode);
        if (result.success) {
            eSignUrl = result.redirect;
            return;
        } else {
            router.goto("/");
        }
    })

    function back() {
        router.goto("/documents");
    }
</script>

{#if eSignUrl}
    <div class="esign">
        <iframe title="E-Signatures" src={eSignUrl} frameborder="0"></iframe>
    </div>
{/if}

<div class="working" class:show={working}>
    <svg
            class="loading"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            height="4em"
            width="4em"
    >
        <circle
                cx="50"
                cy="50"
                r="40"
                stroke="#2691a6"
                stroke-width="10"
                fill="none"
                stroke-dasharray="226.194 226.194"
        />
    </svg>
    <h2>Please wait...</h2>
</div>


<style>
.working {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    z-index: 100;
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.working.show {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@-webkit-keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.working > svg {
    -webkit-animation: rotating 2s linear infinite;
            animation: rotating 2s linear infinite;
}

div.esign {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 110;
}

div.esign > iframe {
    width: 100%;
    height: 100%;
}</style>
<script>

    import {createEventDispatcher} from 'svelte';
    const dispatch = createEventDispatcher();

    import Document from "./Document.svelte";
    import Uploader from "./Uploader.svelte";
    import SmartSearch from "./SmartSearch.svelte";

    export let needs = [];

</script>

<div class="documents">
    {#each needs as need, index (`${need.id}_${index}`)}
        <Document>
            <span slot="icon">
                {#if need.completed == false}
                    {#if need.type == "esign"}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#ccc" width="2em" style="margin-right:1em">
                            <path d="M0 18h20v1H0zm-.003-6.155l1.06-1.06 4.363 4.362-1.06 1.06z"/>
                            <path d="M.004 15.147l4.363-4.363 1.06 1.061-4.362 4.363zM17 5c0 9-11 9-11 9v-1.5s8 .5 9.5-6.5C16 4 15 2.5 14 2.5S11 4 10.75 10c-.08 2 .75 4.5 3.25 4.5 1.5 0 2-1 3.5-1a2.07 2.07 0 0 1 2.25 2.5h-1.5s.13-1-.5-1C16 15 16 16 14 16c0 0-4.75 0-4.75-6S12 1 14 1c.5 0 3 0 3 4z"/>
                        </svg>
                    {:else}
                        <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 220 307" width="2em" style="margin-right:1em">
                            <path id="Ellipse" d="M200 153 C200 103.294 159.706 63 110 63 60.294 63 20 103.294 20 153 20 202.706 60.294 243 110 243 159.706 243 200 202.706 200 153 Z"
                                  fill="none"
                                  stroke="#DB214C"
                                  stroke-width="40"
                                  stroke-opacity="1"
                                  stroke-linejoin="round"
                                  stroke-linecap="round"/>
                        </svg>
                    {/if}
                {:else}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 220 307" width="2em" style="margin-right:1em">
                        <path
                                id="Path"
                                d="M75 162.906 L100.094 188 145 118"
                                fill="none"
                                stroke="#348f96"
                                stroke-width="25"
                                stroke-opacity="1"
                                stroke-linejoin="round"
                                stroke-linecap="round"/>
                        <path
                                id="Ellipse"
                                d="M200 153 C200 103.294 159.706 63 110 63 60.294 63 20 103.294 20 153 20 202.706 60.294 243 110 243 159.706 243 200 202.706 200 153 Z"
                                fill="none"
                                stroke="#348f96"
                                stroke-width="40"
                                stroke-opacity="1"
                                stroke-linejoin="round"
                                stroke-linecap="round"/>
                    </svg>
                {/if}
            </span>
            <h3>{need.description}</h3>
            {#if need.notes }
                <p>{need.notes}</p>
            {/if}
            {#if need.completed == false}
                {#if need.type == "need" }
                    <Uploader aip={false}
                              basic={true}
                              uploadType={need.description}
                              on:uploaded={(e) => dispatch('uploaded', {index, e})}
                              button="Add"
                    />
                {/if}
                {#if need.type == "smartsearch" }
                    <SmartSearch/>
                {/if}
                {#if need.type == "esign" }
                    <p>
                        <button on:click={() => dispatch('esign', {need})}>Proceed to Sign</button>
                    </p>
                {/if}
            {/if}
        </Document>
    {/each}
</div>

<style>

.documents-needed .documents .document {
    cursor:pointer;
}

.documents {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
    margin-bottom: 1em;
}

@media screen and (max-width: 1236px) {
    .documents {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 767px) {
    .documents {
        grid-template-columns: 1fr;
    }
}</style>
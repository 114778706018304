<script>

    import api from "../api";
    import {onMount} from "svelte";
    import {user,  activeCase} from "../store";
    import {countries} from "../lists";

    let error = false;
    let working = false;
    let redirecting = false;
    let smartSearchParams = {
        type: "driving_licence",
        issuingCountry: "GBR"
    }

    onMount(() => {
        activeCase.subscribe(value => {
            smartSearchParams = { ...smartSearchParams, ...value.smartSearch.parameters };
        });
    })

    async function executeSmartSearch() {
        working = true;
        let result = {};
        if ($activeCase.smartSearch.uri === undefined) {
            result = await api.smartSearch($activeCase.smartSearch.id, smartSearchParams);
        } else {
           result = { redirect: $activeCase.smartSearch.uri };
        }
        if (result.redirect) {
            window.location.assign(result.redirect);
            redirecting = true;
            working = false;
        } else {
            redirecting = false;
            working = false;
            error = true;
        }
    }
</script>

<div>
    <div class="smart-search">
        {#if $activeCase.smartSearch.uri == undefined}
            <div class="smart-search__form">
                <div class="smart-search__form__item">
                    <label>Document:</label>
                    <select class="active" bind:value={smartSearchParams.type}>
                        <option value="driving_licence">Driving Licence</option>
                        <option value="passport">Passport</option>
                    </select>
                </div>
                <div class="smart-search__form__item">
                    <label>Country Issued:</label>
                    <select class="active" bind:value={smartSearchParams.issuingCountry}>
                        {#each countries as country}
                            <option value="{country.code}">{country.country}</option>
                        {/each}
                    </select>
                </div>
            </div>
        {/if}
        {#if error}
            <div class="smart-search__error">
                <p>There was problem processing your request, if you continue to receive message then please contact us.</p>
            </div>
        {/if}
        <div class="smart-search__button">
            <button class="smart-search__confirm" on:click={async () => executeSmartSearch()} disabled={working}>
                <span>{ redirecting ? "Redirecting..." : working ? "Please Wait..." : "Confirm Identity" }</span>
                <span class="working" class:show={working || redirecting}>
                    <svg
                            class="loading"
                            viewBox="0 0 100 100"
                            xmlns="http://www.w3.org/2000/svg"
                            height="1.5em"
                            width="1.5em"
                    >
                        <circle
                                cx="50"
                                cy="50"
                                r="40"
                                stroke="white"
                                stroke-width="10"
                                fill="none"
                                stroke-dasharray="226.194 226.194"
                        />
                    </svg>
                </span>
            </button>
        </div>
    </div>
</div>

<style>

.smart-search {
    margin-top: 0.5rem;
}
.smart-search__error p {
    padding:0;
    margin:0;
}

.smart-search__form {
    padding: 0;
}

.smart-search__form__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center;
    -ms-flex-line-pack: start;
        align-content: start;
    margin: 0.25rem 0;
}

.smart-search__form__item label {
    font-size: 0.8em;
    min-width:110px;
    display: block;
    white-space: nowrap;
    word-wrap: normal;
}

.smart-search__form__item select {
    width:100%;
    padding:3px;
}

.smart-search__confirm {

    font-size: 0.6em;
}

.smart-search__error p {
    font-size: 0.8em;
    padding-bottom: 0.5rem;
}

@media screen and (max-width: 767px) {
    .smart-search__form__item label {
        min-width: initial;
    }
    .smart-search__form__item {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: start;
    }

}

.working {
    display: none;
}

.working.show {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@-webkit-keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.working > svg {
    -webkit-animation: rotating 2s linear infinite;
            animation: rotating 2s linear infinite;
    margin-left:0.5em;
}</style>
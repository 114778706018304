<script>
    import {createEventDispatcher} from 'svelte';
    import {formatDocumentDate} from "../utils";
    import {user, activeCase} from "../store";

    const dispatch = createEventDispatcher();

    export let doc = null;
    export let closable = false;
    export let icon = true;

    function remove() {
        dispatch("remove");
    }
</script>

<div class="document">
    <slot name="icon">
        {#if icon}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 220 307" width="2em">
                <path
                        fill="#f2f2f2"
                        stroke="#ccc"
                        stroke-miterlimit="10"
                        stroke-width="10"
                        d="M169.25 5H5v297h210V50.75L169.25 5z"
                />
                <path
                        fill="#fff"
                        stroke="#ccc"
                        stroke-miterlimit="10"
                        stroke-width="10"
                        fill-rule="evenodd"
                        d="M169.25 5H168v47h47v-1.25L169.25 5z"
                />
                <path
                        fill="none"
                        stroke="#ccc"
                        stroke-miterlimit="10"
                        stroke-width="10"
                        d="M43.76 119.21h132.48M43.76 236.79h132.48M43.76 207.39h132.48M43.76 178h132.48M43.76 148.61h132.48"
                />
            </svg>
        {/if}
    </slot>
    <div>
        <slot>
            <h3>{ doc.displayName }</h3>
            <p>Uploaded { formatDocumentDate(doc.createdAt) }</p>
            <p>
                <a href={`/api/${$user.accountId}/case/${$activeCase.id}/documents/${doc.id}`} download={doc.fileName} tinro-ignore>Download</a>
            </p>
        </slot>
    </div>
    {#if closable}
        <svg
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                class="remove"
                on:click={remove}
        >
            <line
                    x1="5"
                    y1="5"
                    x2="95"
                    y2="95"
                    stroke="#cccccc"
                    stroke-width="20"
            />
            <line
                    x1="95"
                    y1="5"
                    x2="5"
                    y2="95"
                    stroke="#cccccc"
                    stroke-width="20"
            />
        </svg>
    {/if}
</div>

<style>
.document {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: #ffffff;
    padding: 1em;
    border: 1px solid #cccccc;
    text-decoration: none;
    color: #2c3e50;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.document > svg:first-child,
.document span svg {
    margin-right: 1em;
}

.document > div {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}

.document > div > :global(h3) {
    margin: 0;
}

.document > div > :global(p) {
    font-size: 0.8em;
    color: #666666;
    margin: 0.5em 0;
}

.document > div > :global(*:last-child) {
    margin-bottom: 0;
}

.remove {
    cursor: pointer;
    margin-left: 1em;
}</style>

<script>
  import {onMount} from "svelte";
  import {router} from "tinro";
  import api from "../api";
  import {signIn as setUserSignedIn} from "../store";

  export let code;
  let alreadyActivated = false;
  let showAlreadyActivated = false;
  let password = "", confirmPassword = "", error = "", working = false;

  onMount(async () => {
    alreadyActivated = !(await api.checkInvite(code));
  });

  async function setPassword() {
    error = "";
    if (!password) {
      error = "Please enter a password";
      return;
    }
    if (password.length < 8) {
      error = "Please choose a password with at least 8 characters";
      return;
    }
    if (!confirmPassword) {
      error = "Please confirm your chosen password";
      return;
    }
    if (password !== confirmPassword) {
      error = "Passwords do not match";
      return;
    }
    working = true;
    const result = await api.redeemInvite(code, password);
    working = false;
    if (result.success) {
      const user = {
        token: result.token,
        profile: result.profile,
        expires: Date.now() + result.expiresIn * 1000
      };
      setUserSignedIn(user);
      router.goto("/");
    } else {
      error = "Unable to process your request right now. Please try later";
      showAlreadyActivated = alreadyActivated;
    }
  }

  function handleEnter(e) {
    if (!working && e.key === "Enter") {
      setPassword();
    }
  }
</script>

<div class="container">
    <h1>Activate Your Account</h1>
    <p>Welcome to the Believe Loans Customer Portal. Please choose a password for your account to continue (at least 8
        characters):</p>
    {#if showAlreadyActivated}
    <p class="activation-message">
        <strong>Already activated?</strong><br/>
        If you have already activated your account and are just trying to sign in, please <a href="/">sign in here</a>.
    </p>
    {/if}
    <div class="form">
        <label for="password">Password:</label>
        <input id="password" type="password" bind:value={password} on:keyup={handleEnter}/>
        <label for="confirmPassword">Confirm Password:</label>
        <input id="confirmPassword" type="password" bind:value={confirmPassword} on:keyup={handleEnter}/>
        <button on:click={setPassword}>Set Password</button>
        <span class="error">{error}</span>
    </div>
</div>

<style>
.activation-message {
    background-color: #d9edf7;
    color: #31708f;
    padding: 1em;
    border: 1px solid #31708f;
    border-radius: 4px;
}</style>
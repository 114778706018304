<script>
  import {createEventDispatcher} from 'svelte';

  const dispatch = createEventDispatcher();

  import api from "../api";
  import Document from "./Document.svelte";
  import {aipAccessCode} from "../utils";

  export let aip = false;

  let fileSelect;
  let uploadQueue = [];
  let working = false;

  export let uploadName = "";
  export let uploadType = "default";
  export let basic = false
  export let button = undefined;

  function browse() {
    console.log(uploadType);
    fileSelect.click();
  }

  const allowedTypes = new Set(["application/pdf", "image/jpeg", "image/png"]);
  let fileCounter = 0;

  function addToUploadQueue() {
    for (const file of fileSelect.files) {
      const exists = uploadQueue.some(f => f.file.name === file.name && f.file.type === file.type && f.file.size === file.size && f.file.lastModified === file.lastModified);
      if (!exists) {
        let error = null;
        if (file.size > 26214400) {
          error = "File is larger than 25MB";
        } else if (!allowedTypes.has(file.type)) {
          error = "File type not supported";
        }
        const name = uploadType === "default" ? null : fileCounter === 0 ? uploadType : `${uploadType} ${fileCounter + 1}`;
        uploadQueue.push({
          id: fileCounter++,
          file: file,
          error: error,
          status: error || "Ready to upload",
          name
        });
        uploadQueue = uploadQueue;
      }
    }
  }

  function removeFromUploadQueue(index) {
    uploadQueue.splice(index, 1);
    uploadQueue = uploadQueue;
  }

  async function upload() {
    working = true;
    for (let i = 0; i < uploadQueue.length; i++) {
      const file = uploadQueue[i];
      if (file.error) {
        uploadQueue[i].status = `Skipped: ${file.error}`;
        continue;
      }
      const uploadParams = {
        file: file.file,
        name: file.name,
        progress: e => {
          if (e.type === "loadstart") {
            uploadQueue[i].status = `Uploading...`;
          } else if (e.type === "progress") {
            uploadQueue[i].status = `Uploading (${(
              (e.loaded / e.total) *
              100.0
            ).toLocaleString("en-GB", {maximumFractionDigits: 1})}%)`;
          } else if (e.type === "load") {
            uploadQueue[i].status = "Processing...";
          } else if (e.type === "error") {
            uploadQueue[i].status = "Failed";
          }
        }
      }
      const result = aip
        ? await api.aipUpload({accessCode: aipAccessCode(), uploadType, ...uploadParams})
        : await api.upload(uploadParams);
      if (result.doc) {
        uploadQueue[i].doc = result.doc;
      }
      uploadQueue[i].status = result.success ? "Done!" : "Failed";
    }
    const completed = uploadQueue.filter(f => f.doc);
    const failed = uploadQueue.filter(f => !f.doc);
    uploadQueue = failed;
    working = false;
    if (completed.length > 0) dispatch('uploaded', {completed});
    if (failed.length > 0) dispatch('failed', {failed});
  }

</script>

<div class="uploader">
    <div class="documents {basic ? `blocks` : ``}">
        {#each uploadQueue as doc, index (`${doc.fileName}_${index}`)}
            <Document icon={!basic} doc={doc} closable on:remove={() => removeFromUploadQueue(index)}>
                {#if !basic}
                    <h3>{doc.file.name}</h3>
                    <p>{doc.status}</p>
                {:else}
                    <p>{doc.file.name}</p>
                {/if}
            </Document>
        {/each}
    </div>
    <div class="upload-buttons {basic ? `small` : ``}">
        {#if aip}
            <button class="back" on:click={() =>  dispatch('back')}>Back..</button>
        {/if}
        <input bind:this={fileSelect} on:change={addToUploadQueue} type="file" id="fileSelect" multiple/>

        <button on:click={() => browse(uploadType)} disabled={working}>{button ? button : uploadName ? `Upload ${uploadName}` : "Upload"}</button>

        {#if uploadQueue.length}
            <button class="start-upload" on:click={upload} disabled={working}>Start Uploading</button>
        {/if}
    </div>
</div>

<style>

.back {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(154, 154, 154, 1)), to(rgba(75, 75, 75, 1)));
    background-image: linear-gradient(to bottom, rgba(154, 154, 154, 1) 0%, rgba(75, 75, 75, 1) 100%);
    border-color: rgba(75, 75, 75, 1);
}

.documents {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
    margin-bottom: 1em;
}

.documents.blocks {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    grid-template-columns: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 0.5em;
    margin-bottom: 0.5em;
}

.documents.blocks :global(.document) {
    border: 0;
    padding: 0px;
}

input[type=file] {
    display: none;
}

.upload-buttons.small {
    font-size: 0.8em;
}

.start-upload {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#8ed14b), to(#669635));
    background-image: linear-gradient(to bottom, #8ed14b 0%, #669635 100%);
}

.start-upload:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#adff5c), to(#8acc47));
    background-image: linear-gradient(to bottom, #adff5c 0%, #8acc47 100%);
}

@media screen and (max-width: 1236px) {
    .documents {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 767px) {
    .documents {
        grid-template-columns: 1fr;
    }

    .upload-buttons button {
        display: block;
        width: 100%;
        margin-bottom: 0.5em;
    }

    .upload-buttons button:last-of-type {
        margin-bottom: 0;
    }
}</style>
<script>
  import {onMount, createEventDispatcher} from "svelte"
  import { createWidget } from '@typeform/embed'
  import api from "../api"
  import { aipAccessCode } from "../utils"

  const dispatch = createEventDispatcher();
  
  onMount(() => {
    createWidget('hqVJ3Mdy', {
      container: document.querySelector('#expenditure-form'),
      hidden: {
        access_code: aipAccessCode()
      },
      onSubmit: async (event) => {
        await api.expenditureResult(event.response_id);
        dispatch('completed')
      }
    })
  })
</script>
<div id="expenditure-form"></div>
<style>
@import '../../node_modules/@typeform/embed/build/css/widget.css';

:global(.typeform-widget iframe) {
    width: 100%;
    border: none;
    height: 500px;
}</style>
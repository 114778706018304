<script>
    import { DateTime } from "luxon";
    import api from '../api';
    
    export let quote = null;

    let bestTimeToCall = '';
    let bestDateToCall = '';
    let complete = false;

    $: title = `Thank You${quote.name ? `, {quote.name}!` : ``}`;
    
    const callbackTimes = (date) => {
        console.log(date.weekday)
        const start = 9;
        const end = date.weekday === 5 ? 14 : 19;
        let times = [];
        for (let time = start; time <= end; time++) {
            times.push({value: { hour: time, minute: 0, seconds: 0 }, label: `${time}:00`})
            times.push({value: { hour: time, minute: 15, seconds: 0 }, label: `${time}:15`})
            times.push({value: { hour: time, minute: 30, seconds: 0 }, label: `${time}:30`})
            if(date.weekday < 5 && time !== end) {
                times.push({value: {hour: time, minute: 45, seconds: 0}, label: `${time}:45`})
            }
        }
        
        if(date.weekday < 5) {
            times = times.slice(2)
        }
        
        return times;
    }

    const callbackDays = () => {
        const now = DateTime.now();
        let days = [{value: now, label: 'Today', dayOfWeek: now.weekday}, {value: now.plus({ days: 1 }), label: 'Tomorrow', dayOfWeek: now.plus({ days: 1 }).weekday}];
        days = days.filter(d => d.dayOfWeek < 6);
        for (let day = 2; days.length < 7; day++) {
            if(now.plus({ days: day }).weekday < 6) {
                days.push({
                    value: now.plus({days: day}),
                    label: now.plus({days: day}).weekdayLong,
                    dayOfWeek: now.plus({days: day}).weekday
                })
            }
        }
        
        return days;
    }

    const handleSubmit = async () => {
        await api.requestCallback(bestDateToCall.set(bestTimeToCall).toUTC().toISO())
        complete = true;
    }

</script>

<div class="step3">
    <div class="container">
        <h1>{title}</h1>
        <h2>What happens next?</h2>
    </div>
    <div class="thanks">
        <div class="container">
            <p>One of our award winning team will be in touch.</p>
            <p>To finalise your application, is there a good time to call?</p>
            <p>Let us know here:</p>
            <form on:submit|preventDefault={handleSubmit}>
                {#if !complete}
                    <label>
                        <select bind:value={bestDateToCall}>
                            <option value="" disabled selected>Day</option>
                            {#each callbackDays() as {value, label}, i}
                                <option value={value}>{label}</option>
                            {/each}
                        </select>
                    </label>
                    <label>
                        <select bind:value={bestTimeToCall} disabled={!bestDateToCall}>
                            <option value="" disabled selected>Time</option>
                            {#each callbackTimes(bestDateToCall) as {label, value}, i}
                                <option value={value}>{label}</option>
                            {/each}
                        </select>
                    </label>
                    <button disabled={!bestTimeToCall || !bestDateToCall} type=submit>
                        Submit
                    </button>
                {:else}
                    <div>
                        <svg
                          width="24"
                          height="24"
                          xmlns="http://www.w3.org/2000/svg"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          fill="green"
                          viewBox="0 0 24 24"
                        ><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
                        </svg>
                    </div>
                {/if}
            </form>
        </div>
    </div>
</div>

<style>
.thanks select {
        min-width: 150px;
        height: 43px;
}

@media only screen and (max-width: 600px) {
    .thanks select {
        width: 100%;
        margin-bottom: 10px;
    }
}</style>
<script>
    import { createEventDispatcher, onMount } from "svelte";
    import { formatLoanTerm } from "../utils";
    import api from "../api";

    onMount(async () => {
        await api.aipPageView();
    });

    const dispatch = createEventDispatcher();

    export let quote = null;

    function next() {
        dispatch('next');
    }

</script>

<div class="step1">
    <div class="container">
        <h1>Great News!</h1>
        <h3>Your loan is Approved In Principle</h3>
    </div>
        <div class="terms">
            <div class="container">
                <div>
                    <span>Borrowing</span>
                    <p>&pound;{ quote.loanAmount.toLocaleString("en-GB") }</p>
                </div>
                <div>
                    <span>Term</span>
                    <p>{ formatLoanTerm(quote.loanTerm) }</p>
                </div>
                <div>
                    <span>Initial Interest Rate</span>
                    <p>
                        {
                            quote.initialRate.toLocaleString("en-GB", {
                                maximumFractionDigits: 2
                            })
                        }%
                    </p>
                </div>
                <div>
                    <span>Initial Monthly Payment</span>
                    <p>
                        &pound;{
                        quote.initialMonthlyPayment.toLocaleString("en-GB", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })
                    }
                    </p>
                </div>
            </div>
        </div>

        <div class="container next-steps">
            <p>
                <button class="btn get-my-loan" on:click={() => next()}>
                    <svg
                            width="24"
                            height="24"
                            xmlns="http://www.w3.org/2000/svg"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            fill="white"
                            viewBox="0 0 24 24"
                    >
                        <path
                                d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
                        />
                    </svg>
                    <span>Complete My Application</span>
                </button>
            </p>
            <p>
                <a class="btn call" href="tel:+441302590873">
                    <svg
                            width="24"
                            height="24"
                            xmlns="http://www.w3.org/2000/svg"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            fill="white"
                    >
                        <path
                                d="M16 22.621l-3.521-6.795c-.007.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.082-1.026-3.492-6.817-2.106 1.039c-1.639.855-2.313 2.666-2.289 4.916.075 6.948 6.809 18.071 12.309 18.045.541-.003 1.07-.113 1.58-.346.121-.055 2.102-1.029 2.11-1.033zm-2.5-13.621c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm9 0c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm-4.5 0c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5z"
                        />
                    </svg>
                    <span>Call Us</span>
                </a>
            </p>
            <p>
                You can call us on <strong>01302 590 873</strong>.
            </p>
        </div>

</div>

<style>

.terms {
    background-color: #d5f0f5;
}

.terms > .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
}

.terms > .container > div {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding: 0.5em;
    border-left: 1px solid #abe1ec;
}

.terms > .container > div:last-child {
    border-right: 1px solid #abe1ec;
}

.terms > .container > div > span {
    font-weight: bold;
    color: hsl(190, 64%, 30%);
    display: block;
    margin: 1em 0;
}

.terms > .container > div > p {
    color: #124853;
    font-size: 1.75em;
}

.next-steps {
    margin-bottom: 2em;
}

.next-steps > div {
    margin-top: 2em;
}

.btn {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    border-radius: 4px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#4bbcd0), to(#348f96));
    background-image: linear-gradient(to bottom, #4bbcd0 0%, #348f96 100%);
    border: 1px solid #348f96;
    color: white;
    padding: 0.5em;
    text-decoration: none;
    font-weight: bold;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    min-height: 30px;
}

.btn > span {
    margin-left: 0.5em;
}

@media screen and (max-width: 1236px) {

    .terms > .container {
        padding: 0;
    }

}

@media screen and (min-width: 766px) {

    .get-my-loan {
        padding:.5em 1em;
        font-size:2em;
    }

    .get-my-loan > svg {
        width:36px;
        height:36px;
    }

    .call {
        display: none;
    }

}

@media screen and (max-width: 767px) {

    .get-my-loan {
        font-size: 1.5em;
        width:100%;
    }

    .btn, .call {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .terms > .container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }

    .terms > .container > div {
        border-left: none;
        border-top: 1px solid #abe1ec;
    }

    .terms > .container > div:last-child {
        border-bottom: 1px solid #abe1ec;
    }

}</style>